import axios from 'axios'
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from '../components/button'
import Header from '../components/header'
import SEO from '../components/seo'
import Tile from '../components/tile'
import Title from '../components/title'
import { Text } from '../components/typography'
import Arrow from '../images/svg/arrow.svg'
import Fly from '../images/svg/fly.svg'
import { bruxBezierTimingFunction } from '../styles/animations'
import {
  getMobileSize,
  getSideMarginDesktop,
  getSideMarginMobile,
  getTabletSize
} from '../styles/theme-selectors'
import Layout from './layout'

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2.5rem ${getSideMarginDesktop};

  /* Again, why doesn't this work as a styled(Title), I don't get it */
  h2 {
    margin-bottom: 2.3125rem;
  }

  @media screen and (max-width: ${getMobileSize}) {
    padding: 2.5rem ${getSideMarginMobile};
  }
`

const FormContainer = styled.div`
  > input,
  select {
    background-color: #0c1424;
    border: 2px solid rgba(255, 255, 255, 0.2);
    transform: translateY(-0.3125rem);
    margin: 0 1rem;
    text-indent: 1rem;
    border-radius: 0.3rem;
    font-size: 17px;
    color: white;
    font-weight: 300;
    option {
      background-color: #0c1424 !important;
      color: white;
    }
    :focus {
      outline: none !important;
      border-color: rgba(228, 149, 224, 0.5);
      background-color: rgba(228, 149, 224, 0.08);
    }
  }
`

const Input = styled.input<{ marginOnMobile?: boolean }>`
  width: 20rem;
  height: 2.875rem;

  @media screen and (max-width: ${getMobileSize}) {
    display: block;
    width: 100%;
    margin: 0 ${({ marginOnMobile = false }) => (marginOnMobile ? '1rem' : 0)} !important;
  }
`

const Select = styled.select<{ marginOnMobile?: boolean }>`
  width: 15.625rem;
  height: 3.25rem;
  text-indent: 0.5rem;

  @media screen and (max-width: ${getMobileSize}) {
    display: block;
    width: 100%;
    margin: 0 ${({ marginOnMobile = false }) => (marginOnMobile ? '1rem' : 0)} !important;
  }
`

const StyledText = styled(Text)<{
  inlineOnMobile?: boolean
  lowLineHeightMobile?: boolean
}>`
  display: inline;
  line-height: 5rem;
  @media screen and (max-width: ${getMobileSize}) {
    display: ${({ inlineOnMobile = false }) =>
      inlineOnMobile ? 'inline' : 'block'};
    line-height: ${({ lowLineHeightMobile = false }) =>
      lowLineHeightMobile ? '2rem' : '5rem'};
  }
`

const Break = styled.br<{ mobile?: boolean }>`
  display: ${({ mobile = false }) => (mobile ? 'none' : 'inline')};
  @media screen and (max-width: ${getTabletSize}) {
    display: ${({ mobile = false }) => (mobile ? 'inline' : 'none')};
  }
`

const Submit = styled(Button)`
  width: 8.125rem;
  margin-top: 1.5625rem;
`

const ConfirmationContainer = styled.div<{ opacity?: number }>`
  opacity: ${({ opacity }) => opacity || 0};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.5s;
  ${bruxBezierTimingFunction};
`

const ConfirmationDialog = styled.div`
  display: flex;
  width: 25rem;
`

const GetInTouch: FunctionComponent = () => {
  const getFieldValueFor = (fieldName: string) =>
    (document.getElementsByName(fieldName)[0] as HTMLInputElement).value

  const sendMail = () => {
    setSendStatus('sending')
    axios
      .post(`${process.env.GATSBY_API_URL}/sendMail`, {
        name: getFieldValueFor('name'),
        organisation: getFieldValueFor('organization'),
        goal: getFieldValueFor('goal'),
        budget: getFieldValueFor('budget'),
        contactInfo: getFieldValueFor('contact-info')
      })
      .then(() => setSendStatus('sent'))
      .catch(() => {
        setSendStatus('failed')
        alert('Please fill in all fields to send us a message')
      })
  }

  const [sendStatus, setSendStatus] = useState<
    'idle' | 'sending' | 'sent' | 'failed'
  >('idle')
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    sendStatus === 'sent' && setTimeout(() => setOpacity(1), 100)
  }, [sendStatus])

  return (
    <Layout>
      <SEO title="Get in touch" />
      <Header />
      <Main>
        <Title>Contact</Title>
        <FormContainer>
          <StyledText>Hola, I'm </StyledText>
          <Input name="name" placeholder="your name goes here" />
          <StyledText> hailing from </StyledText>
          <Input name="organization" placeholder="your organization" />
          <Break />
          <StyledText> and I would like your help with </StyledText>
          <Input name="goal" placeholder="what do you want to achieve" />
          <StyledText inlineOnMobile> I am looking to stay</StyledText>
          <Break />
          <StyledText inlineOnMobile> around a</StyledText>
          <StyledText inlineOnMobile> budget of</StyledText>
          <Break mobile />
          <Select name="budget">
            <option value="default">pick a range</option>
            <option value="less than $15,000">less than $15,000</option>
            <option value="less than $50,000">less than $50,000</option>
            <option value="less than $150,000">less than $150,000</option>
            <option value="more than $150,000">more than $150,000</option>
            <option value="none of your business">none of your business</option>
          </Select>
          <StyledText> You can reach me at </StyledText>
          <Break mobile />
          <Input name="contact-info" placeholder="contact" />

          <Break />
          <Break mobile />

          <StyledText lowLineHeightMobile>
            to get a dialogue going. Hope to hear from you soon!
          </StyledText>

          <Submit onClick={() => sendStatus !== 'sending' && sendMail()}>
            {sendStatus === 'sending' ? 'Sending...' : 'Send'}{' '}
            {sendStatus !== 'sending' && <Arrow />}
          </Submit>
        </FormContainer>
      </Main>

      {sendStatus === 'sent' && (
        <ConfirmationContainer opacity={opacity}>
          <ConfirmationDialog>
            <Tile
              color="darkPurple"
              image={<Fly />}
              text="Thanks for getting in touch"
              subText={<Fragment>We'll get back to you soon</Fragment>}
              link="/"
            />
          </ConfirmationDialog>
        </ConfirmationContainer>
      )}
    </Layout>
  )
}

export default GetInTouch
