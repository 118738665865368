import { Link } from 'gatsby'
import React, { FunctionComponent, ReactChild, ReactElement } from 'react'
import styled from 'styled-components'
import { Slider } from '../styles/animations'
import { getMobileSize, getTabletSize } from '../styles/theme-selectors'
import { Title4, Title6 } from './typography'

interface Props {
  color: string
  image: ReactElement
  text: string
  subText: string | ReactChild
  link: string
}

const Container = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 1.5rem;
  padding: 2rem;
  text-decoration: none;
  transition: transform 0.2s;

  @media screen and (max-width: ${getTabletSize}) {
    flex-direction: row-reverse;
    padding: 1rem 2rem;
  }

  &:hover {
    transform: scale(1.05);
    /* > ${Slider} {
      transform: translateX(0);
    } */
  }
`

const Image = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    max-width: 100%;
    max-height: 100%;
    height: 20vh;
    width: auto;
  }

  @media screen and (max-width: ${getTabletSize}) {
    flex: 1;
  }
`

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Text = styled(Title4)`
  flex: 2;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 500;
`

const SubText = styled(Title6)`
  flex: 1;
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.125rem;

  @media screen and (max-width: ${getTabletSize}) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: ${getMobileSize}) {
    font-weight: 500;
    font-size: 5vmin;
  }
`

const Tile: FunctionComponent<Props> = ({
  color,
  image,
  text,
  subText,
  link,
}) => (
  <Container className={color} to={link}>
    {/* <Slider /> */}
    <Image>{image}</Image>
    <TextContainer>
      <Text>{text}</Text>
      <SubText>{subText}</SubText>
    </TextContainer>
  </Container>
)

export default Tile
